@import "../../styles/index.scss";

.profile {
  margin: 0;
  padding: $spacing-08 $spacing-16;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-02;
  background-color: $white;
  border-radius: 5px;
}

.img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  border: 1px solid $blue;
}

.hr {
  width: 50%;
  margin: $spacing-04;
  padding: 0;
}

.h1 {
  font-weight: $font-weight-3;
}

.title {
  font-size: $font-size-2;
  font-weight: $font-weight-2;
}

.location {
  font-size: $font-size-2;
  font-weight: $font-weight-3;
  text-transform: lowercase;
  font-variant: small-caps;
}

.ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.a {
  color: $black;
  display: flex;
  transition: all 0.3s ease-in;

  &:hover {
    color: $blue;
  }
}

.icon {
  margin: 0;
  padding: $spacing-02;
  font-size: $font-size-3;
}
